<template>
  <img src="@/assets/images/logo/logo.png" alt="Logo" height="24" />
</template>

<script>
export default {
  name: 'LogoComponent',
};
</script>

<style scoped>
/* Add any styles if needed */
</style>
